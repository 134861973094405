import InputMask from 'react-input-mask';
import { Field } from "formik";
import { AiFillCaretRight } from "react-icons/ai";

export const CPFStep1 = (props) => {
    
    const { handleChange, handleBlur, onSteping , validateName} = props;

    return (
        <div>
            <div className="form-group mb-3">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>NOME COMPLETO</strong>
                </label>
                <Field
                    name="nome"
                    className="form-control"
                    placeholder="..."
                    validate={validateName}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.nome && props.touched?.nome ? 'red' : 'initial'}`, // Condição para a cor da borda
                    }}
                    onChange={handleChange}
                    value={props.values.nome}
                />
            </div>
            <div className="form-group mb-3">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CPF</strong>
                </label>
                <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    className="form-control"
                    placeholder="..."
                    name="cpf"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.cpf}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.cpf && props.touched?.cpf ? 'red' : 'initial'}`
                    }}
                />
                {props.errors?.cpf && props.touched?.cpf ? (
                    <div>{props.errors?.cpf}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>SENHA</strong>
                </label>
                <Field
                    type="password"
                    className="form-control"
                    placeholder="..."
                    name="senha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.senha}
                />
                {props.errors?.senha && props.touched?.senha ? (
                    <div>{props.errors?.senha}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CONFIRMAR SENHA</strong>
                </label>
                <input
                    type="password"
                    className="form-control"
                    placeholder="..."
                    name="confirmar"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.confirmar}
                />
                {props.errors?.confirmar && props.touched?.confirmar ? (
                    <div>{props.errors?.confirmar}</div>
                ) : null}
            </div>
            <div className="text-center mt-4">
                <button type="button" onClick={() => onSteping()} className="btn btn-primary">Próxima Etapa <AiFillCaretRight /></button>
            </div>
        </div>
    )
}