import InputMask from 'react-input-mask';
import { Field } from "formik";
import { AiFillCaretRight } from "react-icons/ai";

export const StepContact = (props) => {

    const { handleChange, handleBlur, onSteping, validateEmail } = props;

    return (
        <div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>TELEFONE</strong>
                </label>
                <InputMask
                    mask="(99) 99999-9999"
                    maskChar={null}
                    type="text"
                    className="form-control"
                    placeholder="..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="telefone"
                    value={props.values.telefone}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.telefone && props.touched?.telefone ? 'red' : 'initial'}`, // Condição para a cor da borda
                    }} />
            </div>
            {props.errors?.telefone && props.touched?.telefone ? (
                <div>{props.errors.telefone}</div>
            ) : null}
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>EMAIL</strong>
                </label>
                <Field
                    className="form-control"
                    placeholder="..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    value={props.values.email}
                    validate={validateEmail}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.email && props.touched?.email ? 'red' : 'initial'}`, // Condição para a cor da borda
                    }} />

            </div>
            {props.errors?.email && props.touched?.email ? (
                <div>{props.errors?.email}</div>
            ) : null}
            <div className="text-center mt-4">
                <button
                    onClick={() => onSteping()}
                    className="btn btn-primary ">
                    Próxima Etapa <AiFillCaretRight />
                </button>
            </div>
        </div>
    )
}