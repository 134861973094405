import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { shoppersFactory } from '../../services/PostsService';
import tabDataUsers from './subtabs/tabDataUsers.json'

const tabData = tabDataUsers

function Applys() {
  const [users, setUsers] = useState([]);

  //faz a requisição dos shoppers

  useEffect(() => {
    if (users.length === 0) {
      const fetchProducts = async () => {
        try {
          const response = await shoppersFactory(1, 20);
          if (response.status === 200) {
            setUsers(response.data.result);
          } else {
            console.error('Erro ao obter os produtos:', response.statusText);
          }
        } catch (error) {
          console.error('Erro ao obter os produtos:', error);
        }
      };
      fetchProducts();
    }
  }, [users]);

  return (
    <>
      <div className="custom-tab-1 pb-3">
        <Tab.Container defaultActiveKey={tabData[3].name.toLowerCase()}>
          <Nav as="ul" className="navigatore justify-content-center">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                  <i className={`la la-${data.icon} me-2`} />
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>
      </div>
      <div className="row">
        <div className="col-xl-12 pt-3">
          <div>
            <table className="table mb-0">
              <thead>

              </thead>
              <tbody id="customers"
                className="row"
                style={{
                  width: "100%",
                  padding: 0,
                  justifyContent: 'center'
                }}
              >
                <ul className="btn-reveal-trigger row" style={{
                  width: "100%",
                  padding: 0
                }}>
                  {users.map((Data, index) => (

                    <li className="col-md-4"
                      style={{
                        marginBottom: 6,
                        borderRadius: 6,
                      }} key={index}>
                      <div
                        className="p-3"
                        style={{
                          boxShadow: '2px 2px 2px #f1f1f1',
                        }}>
                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          <span style={{
                            width: '100%',
                            height: 120,
                            backgroundImage: "gray",
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                          }}>
                            <i className='bi bi-person-circle' style={{ fontSize: 45, color: 'white' }} />
                          </span>
                          <div className="media d-flex align-items-center">
                            <div>
                              <br />
                              <div className="media d-flex justify-content-between">
                                <h3 className="mb-0 fs--1">
                                  {Data.name}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <span className='d-flex flex-wrap'>
                              <span style={{ color: 'rgb(245, 71, 73)' ,  wordBreak: 'break-word' }}>
                                <i className="bi bi-headset"></i> contatos
                              </span>
                              <hr style={{width: '100%'}}/>
                              <ul>
                                <li style={{ fontSize: '15px', fontWeight: 'bold' ,  wordBreak: 'break-word' }}>
                                  {Data.email}
                                </li>
                                <li style={{ fontSize: '15px', fontWeight: 'bold'  ,  wordBreak: 'break-word'}}>
                                  { Data.phone }
                                </li>
                              </ul>
                            </span>
                          </span>
                        </span>
                        <hr style={{ margin: '8px 0' }} />
                        <span style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                          <a href={`/produto?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                            ver
                          </a>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default Applys;