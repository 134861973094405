import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Row, Col, Card, Table, Nav, Tab } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { input, Formik } from "formik";
import * as Yup from "yup";
import { productFactory , productUpdateFactory} from '../../services/PostsService';
import tabDataUsers from './subtabs/tabDataUsers.json'

const loginSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "seu nome precisa ter no mínimo três letras")
        .max(50, "seu nome precisa ter no máximo cinquenta letras")
        .required("obrigatório inserir o nome")
});

const tabData = tabDataUsers

const initProduct = {
    id: "",
    name: "",
    description: "",
    costPrice: 0,
    salePrice: 0,
    manufacturingDate: "",
    expiryDate: "",
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    categoryId: "",
    images: null
}

function UserEdit() {
    const [image, setImage] = useState(null);
    const [product, setProduct] = useState('');
    const [productId, setProductId] = useState(null);
    const [toggle, setToggle] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result;
                setImage(result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (image !== null) {
            const base64Image = image.split(',')[1]; // Faz o split apenas aqui
            const updatedProduct = { ...product };
    
            const newImage = {
                "name": " ",
                "position": 0,
                "isMain": true,
                "base64": base64Image // Usa o valor após o split aqui
            };
    
            if (!updatedProduct.images) {
                updatedProduct.images = [newImage]; // Mantém o nome como "images"
            } else {
                updatedProduct.images = [...updatedProduct.images, newImage]; // Mantém o nome como "images"
            }
            setProduct(updatedProduct);
        }
    }, [image]);

    const formHandler = (values , id) => {
        
        const newProduct = { ...product };
        newProduct[id] = values
        setProduct(newProduct)
    };

    //pega o valor get de p na url

    useEffect(() => {
        if (productId === null) {
            console.log('pega o valor get')
            const checkProduct = async () => {
                if (product === '') {
                    try {
                        const params = new URLSearchParams(window.location.search);
                        const pValue = params.get('p');
                        if (pValue) {
                            setProductId(pValue)
                            setToggle(true)
                        } else {
                            console.log('O parâmetro "p" não está presente na URL ou seu valor é vazio.');
                        }
                    } catch (error) {
                        console.error('Erro ao obter o valor de "p" da URL:', error);
                    }
                }
            };
            checkProduct();
        }
    }, [productId]);

    //envia o ID para a consulta na api

    useEffect(() => {
        if (toggle) {
            console.log('toggle')
            const fetchProducts = async () => {
                try {
                    const response = await productFactory(productId);
                    console.log('response')
                    console.log(response)
                    if (response.status === 200) {
                        setProduct(response.data.result);
                        setToggle(false)
                    } else {
                        console.error('Erro ao obter os produtos:', response.statusText);
                        setToggle(false)
                    }
                } catch (error) {
                    console.error('Erro ao obter os produtos:', error);
                    setToggle(false)
                }
            };
            fetchProducts();
        }
    }, [productId]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <span
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'spaceBetween',
                    width: '100%',
                    margin: '20px 0',
                    justifyContent: 'space-around'
                }}
            >
                <h3>Editar Produto</h3> <hr style={{ width: 'Calc(100% - 243px)' }} /></span>
            <div></div>
                        <>
                            <form className="row">
                                <div className="mb-1 col-12">
                                    <h4 style={{ color: '#f54749' }}><i className="bi bi-box-seam"></i> Produto</h4>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>NOME DO PRODUTO</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="..."
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}
                                            id="name"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            value={product.name?product.name:''}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>PESO</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="..."
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}
                                            id="weight"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            value={product.weight?product.weight:''}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>Categoria</strong>
                                        </label>
                                        <div className="form-group mb-3">
                                            <select
                                                name={'categoryId'}
                                                defaultValue={"option"}
                                                className="form-control"
                                                id="categoryId"
                                                onChange={(event) => formHandler(event.target.value , event.target.id)}
                                                value={product.categoryId?product.categoryId:''}
                                            >
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-1">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>DESCRIÇÃO</strong>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            id="description"
                                            value={product.description?product.description:''}
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                                minHeight: 120
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="mb-1 col-12">
                                    <h4 style={{ color: '#f54749' }}><i className="bi bi-arrows-angle-expand"></i> Dimensões</h4>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>ALTURA</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="..."
                                            value={product.height?product.height:''}
                                            id="height"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>LARGURA</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder="..."
                                            value={product.width?product.width:''}
                                            id="width"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>COMPRIMENTO</strong>
                                        </label>
                                        <input
                                            type="textarea"
                                            className="form-control"
                                            placeholder="..."
                                            value={product.length?product.length:''}
                                            id="length"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="mb-1 col-12">
                                    <h4 style={{ color: '#f54749' }}><i className="bi bi-wallet2"></i> Venda</h4>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>PREÇO DE CUSTO</strong>
                                        </label>
                                        <input
                                            type="textarea"
                                            className="form-control"
                                            value={product.salePrice?product.salePrice:''}
                                            placeholder="..."
                                            id="salePrice"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-1 col-md-6">
                                    <div className="form-group mb-3">
                                        <label className="mb-1" style={{ display: 'inline' }}>
                                            <strong>PREÇO DE VENDA</strong>
                                        </label>
                                        <input
                                            type="textarea"
                                            className="form-control"
                                            placeholder="..."
                                            value={product.costPrice?product.costPrice:''}
                                            id="costPrice"
                                            onChange={(event) => formHandler(event.target.value , event.target.id)}
                                            style={{
                                                width: '100%',
                                                border: '1px solid initial',
                                            }}

                                        />
                                    </div>
                                </div>
                            </form>
                            <hr style={{ width: '100%' }} />
                            <h5 style={{ color: '#f54749' }}><i className="bi bi-card-image"></i> Imagem do produto</h5>
                            <form>
                                <input className='mt-2 mb-2' type="file" accept="image/*" onChange={handleImageChange} />
                                {image && (
                                    <>
                                        <div className='mt-2 mb-2'>
                                            <h5 style={{ color: '#dedede' }}>Preview:</h5>
                                            <img src={image} alt="Preview" style={{ maxWidth: '250px' }} />
                                        </div>
                                        {/* <button className="btn btn-secondary mt-3 mb-3" type="submit">Enviar foto</button> */}
                                    </>
                                )}
                            </form>
                            <hr style={{ width: '100%' }} />
                            <button onClick={() => productUpdateFactory(product)} className='btn btn-primary'>
                                ENVIAR
                            </button>
                        </>
        </>
    )
}
export default UserEdit;