import React, { useContext, useState , useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, useLocation , Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import Products from "./pages/ProductList";
import Users from "./pages/UsersList";
import User from "./pages/User";
import AddUser from "./pages/AddUser";
import UserEdit from "./pages/UserEdit";
import Partners from "./pages/PartnersList";
import Partner from "./pages/Partner";
import Applys from './pages/Applys'

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard

import ProductForm from "./pages/ProductForm";
import ProductEdit from "./pages/ProductEdit";
import Product from "./pages/Product";

/// App
import AppProfile from "./pages/AppProfile";
//import Customers from "./pages/Customers";
import Orders from "./pages/OrdersList";
import Order from "./pages/Order";


/// Pages

import { ThemeContext } from "../context/ThemeContext";

import logo from '../images/logo.png'
import LogoutPage from "./layouts/Logout"

const Markup = () => {

  const allroutes = [
    { url: "", component: <Partners /> },
    { url: "usuario", component: <AppProfile /> },
    { url: "consumidores", component: <Users /> },
    { url: "consumidor", component: <User /> },
    { url: "parceiros", component: <Partners /> },
    { url: "parceiro", component: <Partner /> },
    { url: "gerenciar-cadastros", component: <Applys /> },
    { url: "cadastrar", component: <AddUser /> },
    { url: "editar-cadastro", component: <UserEdit /> },
    { url: "produto", component: <Product /> },
    { url: "pedidos-cliente", component: <Orders /> },
    { url: "pedido", component: <Order /> },
    { url: "produtos", component: <Products /> },
    { url: "cadastrar-produto", component: <ProductForm /> },
    { url: "editar-produto", component: <ProductEdit /> },
  ];

  return (
    <>
        <Routes>
            <Route  element={<MainLayout />} > 
                {allroutes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`${data.url}`}
                    element={data.component}
                  />
                ))}
            </Route>
        </Routes>
        <ScrollToTop />
        
    </>
  );
};


function MainLayout() {
  let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
  const [ name ] = useState(dumbToken.name)
  const { menuToggle } = useContext(ThemeContext);
  const location = useLocation();
  const activeRoute = location.pathname.split('/');

  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <nav style={{ width: '100%', position: 'fixed', top: 0, zIndex: 99 }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '6px 0', backgroundImage: 'radial-gradient(circle, #004bbc, #005cc9, #006cd5, #007de1, #128deb)' }}>
          <img src={logo} style={{ width: 150 }} /> 
        </div> 

        <ul className="navbar-nav"
          style={
            {
              width: 90,
              height: 23,
              position: 'fixed',
              right: '5px',
              top: 5
            }
          }>
          <li>
            <Dropdown className=" header-profile2 ">
              <Dropdown.Toggle as="a" className={`nav-link  cursor-pointer `} id="droptoggle1" style={{padding: 1}}>
                <div id="user" className="d-flex align-items-center">
                  <div className="d-flex flex-column align-items-center gap-1 mt-3 text-white" style={{width: '100%'}}>
                    <div>
                      <h6 className="font-w500 mb-0 ms-2 text-white"><i className="la la-bi bi-person-circle " style={{margin: '2px 6px 0'}} /></h6>
                    </div>
                    <div>
                      <h6 className="font-w500 mb-0 ms-2 text-white">{name}</h6>
                    </div>
                    <i className="fas fa-chevron-down"></i>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end">
                <Link to="./usuario" className="dropdown-item ai-icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                  <span className="ms-2">Perfil</span>
                </Link>
                <Link to="./opcoes" className="dropdown-item ai-icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                  <span className="ms-2">Configurações </span>
                </Link>
                <LogoutPage />
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <div id="menuzito" style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '6px 0', gap: '0.4rem', backgroundColor: 'rgb(249 249 249)' }}>
          <Link to={"/consumidores"} style={activeRoute.includes('consumidores') || activeRoute.includes('parceiros')  || activeRoute.includes('usuário')  || activeRoute.includes('gerenciar-cadastros') || activeRoute.includes('cadastrar') || activeRoute.includes('pedidos-cliente') ? { backgroundColor: 'rgb(214 255 101)' } : {}}>
            <i className="bi bi-people-fill" />
            <span>usuários</span>
          </Link>
          <Link to={"/produtos"} style={activeRoute.includes('produtos') || activeRoute.includes('editar-produto') || activeRoute.includes('cadastrar-produto')? { backgroundColor: 'rgb(214 255 101)' } : {}}>
            <i className="bi bi-gift" />
            <span>produtos</span>
          </Link>
        </div>
      </nav>

      <div className="content-body" style={{ minHeight: window.screen.height - 45, margin: 0, paddingTop: 125 }}>
        <div className="container mr-auto ml-auto" style={{ maxWidth: 950 }}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )

};

export default Markup;
