import { AiFillCaretRight , AiOutlineMail } from "react-icons/ai";

export const CPFReview = (props) => {

    const { handleChange, handleBlur, onSteping, validateName } = props;

    return (
        <>
            <div className="d-flex flex-row mt-3 mb-2" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Nome Completo</strong>
                    </label>
                    <p>{props.values.nome ? props.values.nome : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>CPF</strong>
                    </label>
                    <p>{props.values.cpf ? props.values.cpf : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Senha</strong>
                    </label>
                    <p>{props.values.senha ? props.values.senha : null}</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-person-lines-fill" style={{ marginRight: '7px' }}></i> Contato</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Telefone</strong>
                    </label>
                    <p>{props.values.telefone ? props.values.telefone : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Email</strong>
                    </label>
                    <p>{props.values.email ? props.values.email : null}</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-geo-alt-fill" style={{ marginRight: '7px' }}></i> Endereço</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Rua</strong>
                    </label>
                    <p>{props.values.rua ? props.values.rua : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Número</strong>
                    </label>
                    <p>{props.values.numero ? props.values.numero : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Bairro</strong>
                    </label>
                    <p>{props.values.bairro ? props.values.bairro : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Estado</strong>
                    </label>
                    <p>{props.values.estado ? props.values.estado : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 8 }}>Cidade</strong>
                    </label>
                    <p>{props.values.cidade ? props.values.cidade : null}</p>
                </div>
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-primary ">Enviar Cadastro <AiOutlineMail /><AiFillCaretRight /></button>
            </div>
        </>
    )
}