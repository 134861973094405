import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { ordersStoreFactory } from '../../services/PostsService';
import { formatDate , formatTime } from '../helpers/helpers';
import tabDataUsers from './subtabs/tabDataUsers.json'

const tabData = tabDataUsers;

function OrdersList() {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState([]);
  const [productId, setProductId] = useState(null);

  useEffect(() => {
    if (productId === null) {
      const checkProduct = async () => {
        try {
          const params = new URLSearchParams(window.location.search);
          const pValue = params.get('p');
          if (pValue) {
            setProductId(pValue)
            const fetchProducts = async () => {
              try {
                const response = await ordersStoreFactory(1, 100 , pValue);
                if (response.status === 200) {
                  setProducts(response.data.result)
                } else {
                  console.error('Erro ao obter os produtos:', response.statusText);
                }
              } catch (error) {
                console.error('Erro ao obter os produtos:', error);
              }
            };
            fetchProducts();
          } else {
            console.log('O parâmetro "p" não está presente na URL ou seu valor é vazio.');
          }
        } catch (error) {
          console.error('Erro ao obter o valor de "p" da URL:', error);
        }
      };
      checkProduct();
    }
  }, [productId]);

  return (
    <>
      <div className="custom-tab-1 pb-3">
        <Tab.Container defaultActiveKey={'pedidos'}>
          <Nav as="ul" className="navigatore justify-content-center">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                  <i className={`la la-${data.icon} me-2`} />
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>
      </div>
      <div className="row">
        <div className="col-xl-12 pt-3">
          <div>
            <div className="table mb-0">
              <div id="customers"
                className="row"
                style={{
                  width: "100%",
                  justifyContent: 'center'
                }}
              >
                { products.length > 0?
                  <span style={{border: '1px #dedede solid' , borderRadius: '6px', padding: '6px 15px' , display: 'flex' , flexDirection: 'column' , marginBottom: '10px' }}>
                    <h3 style={{padding: 0}}>{products[0].customer.name}</h3>
                    <span style={{padding: 0 }}><span style={{color: '#f54749' }}>CPF:</span> <b>{products[0].customer.taxId}</b></span>
                    <span style={{padding: 0 }}><span style={{color: '#f54749'}}>EMAIL:</span> <b>{products[0].customer.email}</b></span>
                    <span style={{padding: 0 }}><span style={{color: '#f54749'}}>TELEFONE:</span> <b>{products[0].customer.phone}</b></span>
                  </span>
                :null }
                <ul className="btn-reveal-trigger row" style={{
                  width: "100%",
                  padding: 0
                }}>
                  {products.map((Data, index) => (
                    <li className="col-md-4"
                      style={{
                        marginBottom: 6,
                        borderRadius: 6,
                      }} key={index}>
                      <div
                        className="p-3"
                        style={{
                          boxShadow: '2px 2px 2px #f1f1f1',
                        }}>
                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          {/* {Data.images.length > 0 ?
                            <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
                            : <span style={{
                              width: '100%',
                              height: 120,
                              backgroundImage: "gray",
                              margin: '0 auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                            }}>
                              <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                            </span>
                          } */}
                          <div className="media d-flex align-items-center">
                            <div>
                              <div className="media d-flex flex-column justify-content-between">
                                <h5 className="mb-0 fs--1" style={{color: '#f54749'}}>
                                  <i className="bi bi-receipt"></i> Pedido Nº #<span>{products.length > 0? Data.number:null}</span>
                                </h5>                                
                                <hr style={{ margin: '8px 0' }} />
                            
                                <span><span style={{color: '#f54749'}}>CPF:</span><span><b>{products.length > 0? products[0].customer.taxId:null}</b></span></span>
                                <span><span style={{color: '#f54749'}}>DATA:</span><span ><b>{formatDate(products[0].orderDate)}</b></span></span>
                                <span><span style={{color: '#f54749'}}>HORA:</span><span ><b>{formatTime(products[0].orderDate)}</b></span></span>
                              </div> 
                              <br />
                              <label>parceiro:</label>
                              <div className="media d-flex flex-column justify-content-between">
                                <h5 className="mb-0 fs--1">
                                  <i className="bi bi-shop"></i> {products.length > 0? Data.store.name:null}
                                </h5>
                                <p><span style={{color: '#f54749' }}>CNPJ:</span> <b>{products.length > 0? products[0].store.taxId:null}</b></p>
                              </div> 
                            </div>
                          </div>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                          <a href={`/produto?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                            ver
                          </a>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrdersList;