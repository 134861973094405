import { Field } from "formik";
import { AiFillCaretRight } from "react-icons/ai";

export const Location = (props) => {

    const { handleChange, handleBlur, onSteping, validateName } = props;

    return (
        <div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>RUA</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name={"rua"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.rua}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.rua && props.touched?.rua ? 'red' : 'initial'}`, // Condição para a cor da borda
                    }} />
                {props.errors?.rua && props.touched?.rua ? (
                    <div>{props.errors?.rua}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>NÚMERO</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name={"numero"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.numero}
                    style={{
                        width: '100%',
                        border: `1px solid ${props.errors?.numero && props.touched?.numero ? 'red' : 'initial'}`, // Condição para a cor da borda
                    }} />
                {props.errors?.numero && props.touched?.numero ? (
                    <div>{props.errors?.numero}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>BAIRRO</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name={"bairro"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values?.bairro} />
                {props.errors?.bairro && props.touched?.bairro ? (
                    <div>{props.errors?.bairro}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CIDADE</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name={"cidade"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.cidade}
                />
                {props.errors?.cidade && props.touched?.cidade ? (
                    <div>{props.errors?.cidade}</div>
                ) : null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>ESTADO</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name={"estado"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values?.estado} />
                {props.errors?.estado && props.touched?.estado ? (
                    <div>{props.errors?.estado}</div>
                ) : null}
            </div>
            <div className="text-center mt-4">
                <button onClick={() => onSteping()} className="btn btn-primary ">Próxima Etapa <AiFillCaretRight /></button>
            </div>
        </div>
    )
}