
import InputMask from 'react-input-mask';
import { Field } from "formik";
import { AiFillCaretRight } from "react-icons/ai";

export const CNPJStep1 = (props) => {

    const { handleChange, handleBlur, onSteping, validateName } = props;

    return (
        <div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>NOME COMPLETO</strong>
                </label>
                <Field
                    name="nome"
                    className="form-control"
                    style={{
                        width: '100%',
                        border: props.errors.nome ? props.touched.nome ? props.errors.nome.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                    placeholder="..."
                    validate={validateName}
                    onChange={handleChange}
                    value={props.values.nome}
                />

                {
                props.errors.nome ? props.touched.nome ? props.errors.nome.length > 0 ? 
                    <div>{props.errors.nome}</div>
                :null:null:null
                } 
            </div>
            <div className="form-group mb-3">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CPF</strong>
                </label>
                <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    className="form-control"
                    placeholder="..."
                    name="cpf"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.cpf}
                    style={{
                        width: '100%',
                        border: props.errors.cpf ? props.touched.cpf ? props.errors.cpf.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                />
                {props.errors.cpf ? props.touched.cpf ? props.errors.cpf.length > 0 ? 
                    <div>{props.errors.cpf}</div>
                :null:null:null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>NOME FANTASIA</strong>
                </label>
                <Field
                    type="text"
                    className="form-control"
                    placeholder="..."
                    name="fantasia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.fantasia}
                    style={{
                        width: '100%',
                        border: props.errors.fantasia ? props.touched.fantasia ? props.errors.fantasia.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                />
                {props.errors.fantasia ? props.touched.fantasia ? props.errors.fantasia.length > 0 ? 
                    <div>{props.errors.fantasia}</div>
                :null:null:null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CNPJ</strong>
                </label>
                <InputMask
                    mask="99.999.999/9999-99"
                    maskChar={null}
                    className="form-control"
                    placeholder="..."
                    name="cnpj"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.cnpj}
                    style={{
                        width: '100%',
                        border: props.errors.cnpj ? props.touched.cnpj ? props.errors.cnpj.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                />
                {props.errors.cnpj ? props.touched.cnpj ? props.errors.cnpj.length > 0 ? 
                    <div>{props.errors.cnpj}</div>
                :null:null:null}
            </div>
            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>SENHA</strong>
                </label>
                <Field
                    type="password"
                    className="form-control"
                    placeholder="..."
                    name="senha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.senha}
                    style={{
                        width: '100%',
                        border: props.errors.cnpj ? props.touched.senha ? props.errors.cnpj.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                />
                {props.errors.senha ? props.touched.senha ? props.errors.senha.length > 0 ? 
                    <div>{props.errors.senha}</div>
                :null:null:null}
            </div>

            <div className="form-group mb-1">
                <label className="mb-1" style={{ display: 'inline' }}>
                    <strong>CONFIRMAR SENHA</strong>
                </label>
                <input
                    type="password"
                    className="form-control"
                    placeholder="..."
                    name="confirmar"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={props.values.confirmar}
                    style={{
                        width: '100%',
                        border: props.errors.confirmar ? props.touched.confirmar ? props.errors.confirmar.length > 0 ? '1px solid red' : '1px solid #ced4da':'1px solid #ced4da':'1px solid #ced4da',
                    }}
                />
                {props.errors.confirmar ? props.touched.confirmar ? props.errors.confirmar.length > 0 ? 
                    <div>{props.errors.confirmar}</div>
                :null:null:null}
            </div>
            <div className="text-center mt-4"
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                <button
                    type="button"
                    onClick={() => onSteping()}
                    className="btn btn-primary btn-next"
                >
                    <span>Próxima <br />Etapa</span> <span
                        style={{ fontSize: 25 }}><AiFillCaretRight /></span>
                </button>
            </div>
        </div>
    )
}