

import InputMask from 'react-input-mask';
import { Field } from "formik";
import { AiFillCaretRight, AiOutlineMail } from "react-icons/ai";
import { FaMoneyCheck, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlinePix } from "react-icons/md";


export const CNPJReview = (props) => {
    const {
        pix,
        money,
        card,
    } = props;

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-person-circle" style={{ marginRight: '7px' , color: '#6778be' }}></i> dados pessoais</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-1 mb-4 flex-wrap" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Nome Completo</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.nome ? props.dataForm.nome : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>CPF</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.cpf ? props.dataForm.cpf : null}</p>
                </div>

                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Nome Fantasia</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.fantasia ? props.dataForm.fantasia : null}</p>
                </div>

                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>CNPJ</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.cnpj ? props.dataForm.cnpj : null}</p>
                </div>

                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Senha</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.senha ? props.dataForm.senha : null}</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-person-lines-fill" style={{ marginRight: '7px' , color: '#6778be' }}></i> Contato</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-1 mb-4 flex-wrap" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Telefone</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.telefone ? props.dataForm.telefone : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Email</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.email ? props.dataForm.email : null}</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-geo-alt-fill" style={{ marginRight: '7px' , color: '#6778be' }}></i> Endereço</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-1 mb-4 flex-wrap" style={{ gap: '1rem', flexWrap: "wrap" }}>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Rua</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.rua ? props.dataForm.rua : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Número</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.numero ? props.dataForm.numero : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Bairro</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.bairro ? props.dataForm.bairro : null}</p>
                </div>
                <div className="mb-1 text-left">
                    <label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <strong style={{ fontSize: 10 }}>Estado</strong>
                    </label>
                    <hr className="w-full mt-1 mb-1" />
                    <p>{props.dataForm.estado ? props.dataForm.estado : null}</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-0"><i className="bi bi-cash" style={{ marginRight: '7px' , color: '#6778be' }}></i> Pagamento</h5>
                <div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
            </div>
            <div className="d-flex flex-row mt-1 mb-4 flex-wrap" style={{ gap: '1rem', flexWrap: "wrap" }}>
                {props.dataForm.pix ? (
                    <div className="mb-1 text-left">
                        <p>PIX <MdOutlinePix /></p>
                    </div>
                ) : null}

                {props.dataForm.money ? (
                    <div className="mb-1 text-left">
                        <p>Dinheiro <FaMoneyBillAlt /></p>
                    </div>
                ) : null}

                {props.dataForm.card ? (
                    <div className="mb-1 text-left">
                        <p>Cartão <FaMoneyCheck /></p>
                    </div>
                ) : null}
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-primary ">Enviar Cadastro <AiOutlineMail /><AiFillCaretRight /></button>
            </div>
        </div>
    )
}