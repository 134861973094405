import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import {
	loadingToggleAction,
	signupAction,
} from '../../store/actions/AuthActions';
import milho from "../../images/super-kero.png";
import keromais from "../../images/keromais.png";
import ballon from "../../images/baloon.svg"
import { Stepper, Step } from 'react-form-stepper';
import { AiFillCaretRight } from "react-icons/ai";
import { Alert } from "react-bootstrap";
import { CPFStep1, CNPJStep1, Location, StepPayments, CPFReview, CNPJReview, StepContact } from './ClientRegister/FormByStep'
import { Formik  } from "formik";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
	nome: Yup.string()
		.min(3, "seu nome precisa ter no mínimo três letras")
		.max(50, "seu nome precisa ter no máximo cinquenta letras")
		.required("obrigatório inserir o nome"),
	cpf: Yup.string()
		.required("obrigatório inserir o cpf"),
	cnpj: Yup.string()
		.required("obrigatório inserir o cnpj"),
	fantasia: Yup.string()
		.min(3, "seu nome fantasia precisa ter no mínimo três letras")
		.max(50, "seu nome fantasia precisa ter no máximo cinquenta letras")
		.required("obrigatório inserir o nome fantasia"),
	senha: Yup.string()
		.min(6, 'A senha deve ter pelo menos 6 caracteres')
		.required('Campo obrigatório'),
	confirmar: Yup.string()
		.oneOf([Yup.ref('senha'), null], 'As senhas devem coincidir')
		.required('Campo obrigatório'),
	telefone: Yup.string()
		.required("obrigatório inserir o telefone"),
	email: Yup.string()
		.required("obrigatório inserir o email"),
	rua: Yup.string()
		.required("obrigatório inserir o rua"),
	numero: Yup.number()
		.required("obrigatório inserir o número"),
	cidade: Yup.string()
		.required("obrigatório inserir o cidade"),
	estado: Yup.string()
		.required("obrigatório inserir o estado"),
});

function Register(props) {
	const [email, setEmail] = useState('');
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState({});
	const [touched, setTouched] = useState({});
	const [password, setPassword] = useState('');
	const [goSteps, setGoSteps] = useState(0);
	const [dataForm, setDataForm] = useState({
		cidade: "",
		cnpj: "",
		confirmar: "",
		cpf: "",
		email: "",
		estado: "",
		fantasia: "",
		nome: "",
		numero: "",
		rua: "",
		senha: "",
		telefone: ""
	});
	const [step, setStep] = useState(0);
	const [isCNPJ, setIsCNPJ] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [errorMsgBool, setErrorMsgBool] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [money, setMoney] = useState(false);
	const [card, setCard] = useState(false);
	const [pix, setPix] = useState(false);

	const [paymentOptions, setPaymentOptions] = useState({
		pix: false,
		money: false,
		card: false,
	});

	const [selectedOption, setSelectedOption] = useState(null); // Estado para controlar a opção selecionada

	const [fieldValidity, setFieldValidity] = useState({
		nome: false,
		cpf: false,
		senha: false,
		confirmar: false,
		fantasia: false,
		cnpj: false,
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	function isCNPJsetting() {
		setIsCNPJ(true)
		setStep(2)
	}

	function checkFormDataValidity() {
		switch (step) {
			case 2:
				if (
					dataForm.nome.trim().length !== 0 &&
					dataForm.cpf.trim().length !== 0 &&
					dataForm.cnpj.trim().length !== 0 &&
					dataForm.fantasia.trim().length !== 0 &&
					dataForm.senha.trim().length !== 0 &&
					dataForm.confirmar.trim().length !== 0
				) {
					const nameIsValid = validateName(dataForm.nome);
					const cpfIsValid = validateCPF(dataForm.cpf);
					const passwordsMatch = dataForm.senha === dataForm.confirmar;
					return true
				} else {
					//YUP marca todos os campos com o seguinte erro de requerimento
					console.log('Yup.ValidationError')
					console.log(Yup.ValidationError)
					return false;
				}
			case 3:
				if (
					dataForm.telefone.trim().length !== 0 &&
					dataForm.email.trim().length !== 0
				) {
					return true;
				} else {
					return false;
				}
			case 4:
				if (
					dataForm.rua.trim().length !== 0 &&
					dataForm.numero.trim().length !== 0 &&
					dataForm.bairro.trim().length !== 0 &&
					dataForm.cidade.trim().length !== 0 &&
					dataForm.estado.trim().length !== 0
				) {
					return true;
				} else {
					return false;
				}
			default:
				return true;
		}
	}

	function Steppter() {
		return (
			<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
				<Step className="nav-link" onClick={() => setGoSteps(0)} />
				<Step className="nav-link" onClick={() => setGoSteps(1)} />
				<Step className="nav-link" onClick={() => setGoSteps(2)} />
				<Step className="nav-link" onClick={() => setGoSteps(3)} />
				<Step className="nav-link" onClick={() => setGoSteps(4)} />
			</Stepper>
		)
	}

	function SteppterConsumer() {
		return (
			<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
				<Step className="nav-link" onClick={() => setGoSteps(0)} />
				<Step className="nav-link" onClick={() => setGoSteps(1)} />
				<Step className="nav-link" onClick={() => setGoSteps(2)} />
				<Step className="nav-link" onClick={() => setGoSteps(3)} />
			</Stepper>
		)
	}

	function onSignUp(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) return;
		dispatch(loadingToggleAction(true));
		dispatch(signupAction(email, password, navigate));
	}

	const enviarRequisicao = async () => {
		try {
			const url = 'SUA_URL_AQUI';

			const dados = {
				campoEditavel: {},
			};
			const resposta = await axios.post(url, dados);
			console.log('Resposta da requisição:', resposta.data);
		} catch (erro) {
			console.error('Erro na requisição:', erro);
		}
	};

	function validateName(value) {
		let error;
		const nameParts = value !== null && value !== undefined ? value.split(' ') : null;
		if (!nameParts || nameParts.length < 2 || nameParts[1] === '') {
			error = 'Por favor, coloque um nome e um sobrenome.';
			setFieldValidity({
				nome: false,
				cpf: false,
				senha: false,
				confirmar: false,
				fantasia: false,
				cnpj: false,
			});
		} else {
			setFieldValidity({
				nome: true,
				cpf: false,
				senha: false,
				confirmar: false,
				fantasia: false,
				cnpj: false,
			});
		}
		return error;
	}

	function validateEmail(value) {
		let error;
		if (!value) {
			error = 'Required';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = 'Endereço de email inválido';
		}
		return error;
	}

	function onSteping() {
		if (checkFormDataValidity(dataForm)) {
			setStep(step + 1);
			setGoSteps(goSteps + 1);
			setErrorMsgBool(false);
		} else {
			setErrorMsgBool(true);
			setErrorMsg("Por favor, preencha todos os campos antes de avançar.");
		}
	}

	const validateCPF = (value) => {
		const isValidCPF = value.length === 14;
		const isCPFComplete = value.replace(/\D/g, '').length === 11;
		if (isValidCPF && isCPFComplete) {
			setFieldValidity({
				...fieldValidity,
				cpf: true,
			});
		} else {
			setFieldValidity({
				...fieldValidity,
				cpf: false,
			});
		}
		return isValidCPF;
	};

	const formHandler = (values , errors , touched) => {
		// Recebe o dataForm atual
		const newDataForm = { ...dataForm };

		// Concatena as propriedades do objeto values com o dataForm existente
		const mergedDataForm = { ...newDataForm, ...values };

		// Atualiza o dataForm usando a função formHandler
		setDataForm(mergedDataForm);
		setTouched(touched)
		setErrors(errors)
	};

	useEffect(() => {
		let dumbstepper = goSteps + 2
		if (step >= 3) {
			setStep(dumbstepper)
		}
	}, [goSteps])

	useEffect(() => {
		const newDataForm = { ...dataForm };

		if (pix) {
			newDataForm.pix = true;
		} else {
			newDataForm.pix = false;
		}

		if (money) {
			newDataForm.money = true;
		} else {
			newDataForm.money = false;
		}

		if (card) {
			newDataForm.card = true;
		} else {
			newDataForm.card = false;
		}

		setDataForm(newDataForm);
	}, [pix, money, card])

	useEffect(() => {
		if (dataForm.cpf > 0 && dataForm.cpf < 11) {
			setErrors((prevErrors) => ({
				...prevErrors,
				cpf: "teste.",
			}));
		}
	}, [dataForm])

	useEffect(() => {
		// console.log('touched')
		// console.log(touched)
		// console.log('errors')
		// console.log(loginSchema)
	}, [touched , errors])

	return (
		<div id="register-user-ctn" className="authincation">
			<div id="background-client-register" >
			</div>
			<div className="container" >
				<div className="row">
					{step > 0 ?
						<div className="col-md-12" style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: 30 }}>
							<img src={keromais} alt="" style={{ maxWidth: 120, marginRight: 'auto', marginLeft: 'auto', display: 'block', marginBottom: 3, position: "relative" }} />
						</div>
						: null}
					<div style={{ display: "flex", width: '100%', justifyContent: 'center' }}>
						{step > 0 ?
							<span className='ctn-principal ctn-um' style={{ justifyContent: "center", zIndex: 10 }}>
								<span>
									{/* style={{ position: "absolute", marginLeft: "-39rem", marginTop: "-134px" }} */}
									<div id="caixa-do-kero">
										{(() => {
											switch (step) {
												case 1:
													return 'escolha como você vai se cadastrar'
													break;
												case 2:
													return 'aqui você precisa dizer quem é'
													break;
												case 3:
													return 'suas informações de contato'
													break;
												case 4:
													if (isCNPJ) {
														return 'endereço de sua empresa'
													} else {
														return 'diga onde você mora'
													}
													break;
												case 5:
													if (isCNPJ) {
														return 'formas de pagamento que você utilizará'
													} else {
														return 'veja agora se preencheu tudo corretamente'
													}
													break;
												case 6:
													return 'certifique-se está tudo correto!'
													break;
												default:
													return 'defaulte'
											}
										})()}
									</div>
									<img id="super-kero-ballon" src={ballon} alt="" />
									<img id="super-kero-cadastro" src={milho} alt="" style={{ maxWidth: 140 }} />
								</span>
							</span>
							: null}

						<Formik
							initialValues={{ nome: "", cpf: "", cnpj: "", fantasia: "", senha: "", confirmar: "", telefone: "", email: "", rua: "", numero: "", bairro: "", estado: "" }}
							validationSchema={loginSchema}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(false);
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								isValid,

							}) => (
								<form className='ctn-principal ctn-dois' onSubmit={handleSubmit} style={{ position: "relative" }} onChange={() => formHandler(values , errors , touched)}>
									<div style={{ zIndex: 10 }}>

										{
											step === 0 ?
												<div id="welcome" className="flex justify-center">
													<img src={milho} alt="" />
													<span id="delivery">
														<span><div className='arabesco' /></span>
														<span>DELIVERY</span>
														<span><div className='arabesco' /></span>
													</span>
													<img src={keromais} alt="" />
													<button id="btn-welcome" className="btn btn-primary  mt-4" onClick={() => setStep(1)}>CADASTRAR-SE</button>
												</div>
												: step === 1 ?
													<div style={{ display: 'flex', alignContent: 'top', flexDirection: 'column', alignItems: 'center' }}>
														<button className="btn btn-primary  mt-4" onClick={() => isCNPJsetting()} style={{ maxWidth: 400 }}>LOJISTA</button>
														<button className="btn btn-primary  mt-4" onClick={() => setStep(2)} style={{ maxWidth: 400 }}>CONSUMIDOR</button>
													</div>
													: step === 2 ?
														!isCNPJ ?
															<div className="authincation-content">
																<span className="content-span">
																	<div className="row no-gutters">
																		<div className="col-xl-12">
																			<div className="auth-form" style={{borderRadius: 10}}>
																				<h1 className="text-center mb-0 cor-azul">Dados Pessoais</h1>
																				{
																					SteppterConsumer()
																				}
																				{errorMsgBool ?
																					<Alert>
																						<i className="bi bi-exclamation-triangle alert-icon" style={{ marginRight: 7, color: 'red' }}></i>
																						{errorMsg}
																					</Alert>
																					: null}
																				<CPFStep1
																					values={values}
																					errors={errors}
																					touched={touched}
																					handleChange={handleChange}
																					handleBlur={handleBlur}
																					onSteping={onSteping}
																					validateName={validateName}
																				/>
																			</div>
																		</div>
																	</div>
																</span>
															</div>
															:
															<div className="authincation-content">
																<span className="content-span">
																	<div className="row no-gutters">
																		<div className="col-xl-12">
																			<div className="auth-form">
																				<h1 className="text-center mb-0 cor-azul">Dados Pessoais</h1>
																				{Steppter()}
																				{errorMsgBool ?
																					<Alert>
																						<i className="bi bi-exclamation-triangle alert-icon" style={{ marginRight: 7, color: 'red' }}></i>
																						{errorMsg}
																					</Alert>
																					: null}
																				<CNPJStep1
																					values={values}
																					errors={errors}
																					touched={touched}
																					handleChange={handleChange}
																					handleBlur={handleBlur}
																					onSteping={onSteping}
																					validateName={validateName}
																				/>
																			</div>
																		</div>
																	</div>
																</span>
															</div>
														: step === 3 ?
															<div className="authincation-content">
																<span className="content-span">
																	<div className="row no-gutters">
																		<div className="col-xl-12">
																			<div className="auth-form">
																				<h1 className="text-center mb-0 cor-azul">Contato</h1>

																				{
																					isCNPJ ?
																						Steppter()
																						:
																						SteppterConsumer()
																				}
																				{errorMsgBool ?
																					<Alert>
																						<i className="bi bi-exclamation-triangle alert-icon" style={{ marginRight: 7, color: 'red' }}></i>
																						{errorMsg}
																					</Alert>
																					: null}
																				<StepContact
																					values={values}
																					errors={errors}
																					touched={touched}
																					handleChange={handleChange}
																					handleBlur={handleBlur}
																					onSteping={onSteping}
																					validateEmail={validateEmail}
																				/>
																			</div>
																		</div>
																	</div>
																</span>
															</div>
															: step === 4 ?
																<div className="authincation-content">
																	<span className="content-span">
																		<div className="row no-gutters">
																			<div className="col-xl-12">
																				<div className="auth-form">
																					<h1 className="text-center mb-0 cor-azul">Endereço</h1>
																					{
																						!isCNPJ ?
																							SteppterConsumer()
																							:
																							Steppter()
																					}
																					{errorMsgBool ?
																						<Alert>
																							<i className="bi bi-exclamation-triangle alert-icon" style={{ marginRight: 7, color: 'red' }}></i>
																							{errorMsg}
																						</Alert>
																						: null}
																					<Location
																						values={values}
																						errors={errors}
																						touched={touched}
																						handleChange={handleChange}
																						handleBlur={handleBlur}
																						onSteping={onSteping}
																					/>
																				</div>
																			</div>
																		</div>
																	</span>
																</div>
																: step === 5 ?
																	isCNPJ ?
																		<div className="authincation-content">
																			<span className="content-span">
																				<div className="row no-gutters">
																					<div className="col-xl-12">
																						<div className="auth-form">
																							<h1 className="text-center mb-0 cor-azul">Formas de Pagamento</h1>
																							{
																								Steppter()
																							}																		{errorMsgBool ?
																								<Alert>
																									<i className="bi bi-exclamation-triangle alert-icon" style={{ marginRight: 7, color: 'red' }}></i>
																									{errorMsg}
																								</Alert>
																								: null}
																							<StepPayments
																								money={money}
																								card={card}
																								pix={pix}
																								setMoney={setMoney}
																								setCard={setCard}
																								setPix={setPix}
																							/>
																							<div className="text-center mt-4">
																								<button onClick={() => onSteping()} className="btn btn-primary ">Próxima Etapa <AiFillCaretRight /></button>
																							</div>
																						</div>
																					</div>
																				</div>
																			</span>
																		</div>
																		:
																		<>
																			<div className="auth-form">
																				<h1 className="text-center mb-0  cor-azul">Rever Cadastro</h1>
																				{Steppter()}
																				<div style={{ display: "flex", justifyContent: "space-between" }}>
																					<h5 className="mb-0"><i className="bi bi-person-circle" style={{ marginRight: '7px' }}></i> dados pessoais</h5>
																					<div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
																				</div>
																			</div>
																			<div className="authincation-content">
																				<span className="content-span">
																					<div className="row no-gutters">
																						<div className="col-xl-12">
																							<CPFReview dataForm={dataForm} />
																						</div>
																					</div>
																				</span>
																			</div>
																		</>
																	: step === 6 ?
																		<>
																			<div className="authincation-content">
																				<span className="content-span">
																					<div className="row no-gutters">
																						<div className="col-xl-12">
																							<div className="auth-form">
																								<h1 className="text-center mb-0 cor-azul">Formas de Pagamento</h1>
																								{
																									Steppter()
																								}
																								<CNPJReview dataForm={dataForm} />
																							</div>
																						</div>
																					</div>
																				</span>
																			</div>
																		</>
																		: null
										}
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div >
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};

export default connect(mapStateToProps)(Register);