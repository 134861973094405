import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Field, Formik } from "formik";
import * as Yup from "yup";
import tabDataUsers from './subtabs/tabDataUsers.json'

const loginSchema = Yup.object().shape({
    nome: Yup.string()
        .min(3, "seu nome precisa ter no mínimo três letras")
        .max(50, "seu nome precisa ter no máximo cinquenta letras")
        .required("obrigatório inserir o nome"),
    cpf: Yup.string()
        .required("obrigatório inserir o cpf"),
    cnpj: Yup.string()
        .required("obrigatório inserir o cnpj"),
    fantasia: Yup.string()
        .min(3, "seu nome fantasia precisa ter no mínimo três letras")
        .max(50, "seu nome fantasia precisa ter no máximo cinquenta letras")
        .required("obrigatório inserir o nome fantasia"),
    senha: Yup.string()
        .min(6, 'A senha deve ter pelo menos 6 caracteres')
        .required('Campo obrigatório'),
    confirmar: Yup.string()
        .oneOf([Yup.ref('senha'), null], 'As senhas devem coincidir')
        .required('Campo obrigatório'),
    telefone: Yup.string()
        .required("obrigatório inserir o telefone"),
    email: Yup.string()
        .required("obrigatório inserir o email"),
    rua: Yup.string()
        .required("obrigatório inserir o rua"),
    numero: Yup.number()
        .required("obrigatório inserir o número"),
    cidade: Yup.string()
        .required("obrigatório inserir o cidade"),
    estado: Yup.string()
        .required("obrigatório inserir o estado"),
});

const tabData = tabDataUsers

function AddUser() {
    const [image, setImage] = useState(null);
    const [nome, setNome] = useState('')

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (image) {
            try {
                const response = await fetch('/upload', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ image }),
                });
                // Handle response accordingly
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container defaultActiveKey={tabData[2].name.toLowerCase()}>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <span
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'spaceBetween',
                    width: '100%',
                    margin: '20px 0',
                    justifyContent: 'space-around'
                }}
            >
                <h3>Adicionar Usuário</h3> <hr style={{ width: 'Calc(100% - 263px)' }} /></span>
            <div></div>
            {/* início */}
            <Formik
                initialValues={{ nome: "", cpf: "", cnpj: "", fantasia: "", senha: "", confirmar: "", telefone: "", email: "", rua: "", numero: "", bairro: "", estado: "" }}
                validationSchema={loginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,

                }) => (
                    <>
                        <div className="row">
                            <div className="mb-1 col-12">
                                <h4 style={{color: '#f54749'}}><i className="bi bi-box-seam"></i> Produto</h4>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>NOME DO PRODUTO</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>PESO</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>Tipo</strong>
                                    </label>
                                    <div className="form-group mb-3">
                                        <select
                                        defaultValue={"option"}
                                        className="form-control"
                                        id="sel1"
                                        >
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-1">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>DESCRIÇÃO</strong>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        id="comment"
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                            minHeight: 120
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-1 col-12">
                                <h4 style={{color: '#f54749'}}><i className="bi bi-arrows-angle-expand"></i> Dimensões</h4>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>ALTURA</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>LARGURA</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>COMPRIMENTO</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        type="textarea"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-1 col-12">
                                <h4 style={{color: '#f54749'}}><i className="bi bi-wallet2"></i> Venda</h4>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>PREÇO DE CUSTO</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        type="textarea"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-1 col-md-6">
                                <div className="form-group mb-3">
                                    <label className="mb-1" style={{ display: 'inline' }}>
                                        <strong>PREÇO DE VENDA</strong>
                                    </label>
                                    <Field
                                        name="nome"
                                        type="textarea"
                                        className="form-control"
                                        placeholder="..."
                                        style={{
                                            width: '100%',
                                            border: '1px solid initial',
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr style={{ width: '100%' }} />
                        <h5 style={{color: '#f54749'}}><i className="bi bi-card-image"></i> Imagem do produto</h5>
                        <form onSubmit={handleSubmit}>
                            <input className='mt-2 mb-2' type="file" accept="image/*" onChange={handleImageChange} />
                            {image && (
                                <>
                                    <div className='mt-2 mb-2'>
                                        <h5 style={{color: '#dedede'}}>Preview:</h5>
                                        <img src={image} alt="Preview" style={{ maxWidth: '250px' }} />
                                    </div>
                                    <button className="btn btn-primary mt-3 mb-3" type="submit">Enviar foto</button>
                                </>
                            )}
                        </form>
                    </>
                )}
            </Formik>
        </>
    )
}
export default AddUser;