import { FaMoneyCheck, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlinePix } from "react-icons/md";
import { Button } from "react-bootstrap";

export const StepPayments = (props) => {
    const { money, card, pix, setMoney, setCard, setPix } = props;

    const handleMoneyClick = () => {
        setMoney(!money); // Inverte o estado atual
    };

    const handleCardClick = () => {
        setCard(!card); // Inverte o estado atual
    };

    const handlePixClick = () => {
        setPix(!pix); // Inverte o estado atual
    };

    return (
        <div>
            <div style={{ display: 'flex', gap: 5 }}>
                <Button
                    className={`me-2 rere ${money ? 'active' : ''}`}
                    variant="outline-info btn-rounded"
                    onClick={handleMoneyClick}
                >
                    <FaMoneyCheck />
                    <span> Cartão</span>
                </Button>
                <Button
                    className={`me-2 rere ${pix ? 'active' : ''}`}
                    variant="outline-info btn-rounded"
                    onClick={handlePixClick}
                >
                    <MdOutlinePix />
                    <span> PIX</span>
                </Button>
                <Button
                    className={`me-2 rere ${card ? 'active' : ''}`}
                    variant="outline-info btn-rounded"
                    onClick={handleCardClick}
                >
                    <FaMoneyBillAlt />
                    <span> Dinheiro</span>
                </Button>
            </div>
        </div>
    );
};