import axiosInstance from '../services/AxiosInstance';
import axios from 'axios';

export function getPosts() {
    return axiosInstance.get(`posts.json`);
}

export function createPost(postData) {
    return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
    return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
    return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}

export function productsFactory(page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    console.log(token)

    const postData = {
        "Page": page,
        "PerPage": perPage
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductsFactoryRequest`,
        postData, config,
    );
}

export function productFactory(value) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const postData = {
        "ProductId": value
    };

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetProductRequest`,
        postData, config,
    );
}


export function shoppersFactory(page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    } 

    const postData = {
        "Page": page,
    "PageSize": perPage,
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreByFiltersCommand`,
        postData, config,
    );
}

export function usersFactory(page, perPage) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    } 

    const postData = {
        "Page": 1,
        "PerPage": 20
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetCustomersRequest`,
        postData, config,
    );
}

export function userFactory(value) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    } 

    const postData = {
        "CustomerId": value
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetCustomerRequest`,
        postData, config,
    );
}

export function partnerFactory(value) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    } 

    const postData = {
        "StoreId": value
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetStoreRequest`,
        postData, config,
    );
}

export function ordersStoreFactory(page, perPage , costumer ) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    } 

    const postData = {
        "StoreId": costumer,
        "Page": page,
        "PerPage": perPage
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/GetOrdersByFilterRequest`,
        postData, config,
    );
}

export function productUpdateFactory(value) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const PostData =
    {
        "categoryId": value.categoryId,
        "costPrice": value.costPrice,
        "createDate": value.createDate,
        "description": value.description,
        "height": value.height,
        "id": value.id,
        "images": value.images ? value.images : [],
        "inactive": value.inactive,
        "length": value.length,
        "name": value.name,
        "salePrice": value.salePrice,
        "weight": value.weight,
        "width": value.width
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/UpdateProductCommand`,
        PostData, config,
    );
}

export function createProduct(value) {
    let dumbToken = JSON.parse(localStorage.getItem('userDetails'));
    const token = 'Bearer ' + dumbToken.data
    const config = {
        headers:
        {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }

    const PostData =
    {
        value
    }

    return axios.post(
        `https://core.pskeromais.com.br/api/v1/AddStockProductCommand`,
        PostData, config,
    );
}